.menu {
    header {
        z-index: 4;
    }
    .dg-menu {
        background-image: url("/images/menu/background.webp");
        background-position: 0 0;
        background-size: 100% auto;
        min-height: 100vh;
        position: relative;
        padding-top: 135px;
        padding-bottom: 50px;
        width: 100%;

        .accordion {

            .card {
                background-color: transparent;
            }

            .collapse {
                &.show {
                    z-index: 3;
                }
            }

            .card-header {
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid white;
                border-radius: 0;
                margin-bottom: 0;
                position: relative;
                transition: all 1s;

                &.last {
                    border-bottom: 0;
                }

                &:before {
                    background-color: #37931b;
                    border-radius: 50%;
                    content: "";
                    position: absolute;
                    height: 6px;
                    top: 34px;
                    left: 25px;
                    width: 6px;
                }

                &.active {
                    &:before {
                        opacity: 0;
                    }
                }

                &.f-d-none {
                    display: none !important;
                }

                button {
                    color: white;
                    padding-left: 20px;
                }
            }

            //.card-header

            .card-body {
                padding: 0;

                .wrap-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid;
                    margin-bottom: 10px;
                    min-height: 50px;

                    .title {
                        color: white;
                        font-weight: 600;
                        font-size: 19px;
                        width: 60%;
                    }
                }

                ul {
                    margin: 0;
                    padding: 0;

                    li {

                        border-bottom: 2px solid rgba(255, 255, 255, 0.4);
                        list-style: none;
                        min-height: 40px;
                        display: block;
                        padding-top: 1rem;
                        padding-left: 0;
                        padding-right: 0;
                        padding-bottom: 15px;

                        .main-text {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            padding-left: 15px;
                            padding-right: 15px;
                            margin: 0;
                            width: 100%;

                            label {
                                font-weight: 200;
                                display: block;
                                text-align: left;
                                position: relative;
                                width: 50%;
                                &:before {
                                    background-color: #37931b;
                                    border-radius: 50%;
                                    content: "";
                                    position: absolute;
                                    height: 6px;
                                    top: 10px;
                                    left: -9px;
                                    width: 6px;
                                }
                            }
                            strong {
                                display: block;
                                text-align: center;
                                width: 25%;
                            }
                            .wrap-buttons {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 25%;

                                button {
                                    margin: 0;
                                }
                            }
                        }
                        //.main-text

                        .description {
                            color: white;
                            font-size: 12px;
                            font-weight: 200;
                            //text-align: left;
                            margin-bottom: 25px;
                            padding-left: 19px;
                            padding-right: 19px;
                            margin-top: 8px;
                        }
                        .image {
                            img {
                                display: block;
                                width: 100%;
                            }
                        }

                    }
                    //li
                }
                //ul
            }

            //.card-body

        }

        //.accordion

    }
}