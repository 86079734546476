body {
    &.widget-on {

        .dg-mainMenu {

            .wrapMenuOptions {

                .item {
                    flex: 0 0 50%;
                    max-width: 50%;

                    a {
                        img {
                            width: 70px;
                        }

                        label {
                            font-size: 20px;
                        }
                    }
                }

                //.item
            }

            //.wrapMenuOptions
        }

        //.dg-mainMenu
    }

    //&.widget-on

    &.noImages-on {
        .dg-init {
            background-image: none;
            background-color: black;
        }
        img {
            display: none !important;

            &.logo {
                display: block !important;
            }
        }
        .imgBack {
            opacity: 0;
        }
    }

    //.&.noImages-on

    &.contrast-on {
        header {
            //filter: invert(1);
            .menuMb {
                .hamburger {
                    filter: invert(1);
                }
            }
            .dg-logo {
                filter: invert(1);
            }
            .dg-mainMenu {
                filter: invert(1);
            }
        }
        .dg-init {
            filter: invert(1);

            .go {
                background-color: black;
            }
        }

        .dg-menu {
            filter: invert(1);
        }

        .play-video {
            background-color: rgba(0,0,0,1);
            filter: invert(1);
        }
    }

    //&.contrast-on

    &.grand-text-level-0 {
        font-size: 16px;

        header {
            .dg-mainMenu {
                .wrapMenuOptions {
                    .item {
                        a {
                            label {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        .dg-menu {
            .accordion {
                .card-body {
                    ul {
                        li {
                            .description {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.grand-text-level-1 {
        font-size: 18px;

        header {
            .dg-mainMenu {
                .wrapMenuOptions {
                    .item {
                        a {
                            label {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
        .dg-menu {
            .accordion {
                .card-body {
                    ul {
                        li {
                            .description {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.grand-text-level-2 {
        font-size: 21px;

        header {
            .dg-mainMenu {
                .wrapMenuOptions {
                    .item {
                        a {
                            label {
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
        }
        .dg-menu {
            .accordion {
                .card-body {
                    ul {
                        li {
                            .description {
                                font-size: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.grand-text-level-3 {
        font-size: 24px;

        header {
            .dg-mainMenu {
                .wrapMenuOptions {
                    .item {
                        a {
                            label {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
        .dg-menu {
            .accordion {
                .card-body {
                    ul {
                        li {
                            .description {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
    //&.grand-text-level-3

    &.saturation-level-0 {
        filter: none;
    }
    //&.saturation-level-0
    &.saturation-level-1 {
        filter: saturate(10);
    }
    //&.saturation-level-1
    &.saturation-level-2 {
        filter: contrast(2);
    }
    //&.saturation-level-2

    &.alignment-level-0 {
        text-align: left;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    text-align: left;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.alignment-level-0
    &.alignment-level-1 {
        text-align: center;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            text-align: center;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.alignment-level-1
    &.alignment-level-2 {
        text-align: right;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            text-align: right;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.alignment-level-2
    &.alignment-level-3 {
        text-align: justify;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            text-align: justify;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    text-align: justify;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.alignment-level-3

    &.line-level-0 {
        line-height: normal;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            line-height: normal;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.line-level-0
    &.line-level-1 {
        line-height: 1;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            line-height: 1;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.line-level-1
    &.line-level-2 {
        line-height: 1.7;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            line-height: 1.7;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    line-height: 1.7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.line-level-2
    &.line-level-3 {
        line-height: 2.1;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            line-height: 2.1;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    line-height: 2.1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.line-level-3

    &.space-level-0 {
        letter-spacing: normal;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            letter-spacing: normal;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    letter-spacing: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.space-level-0
    &.space-level-1 {
        letter-spacing: 3px;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            letter-spacing: 3px;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    letter-spacing: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.space-level-1
    &.space-level-2 {
        letter-spacing: 5px;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            letter-spacing: 5px;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    letter-spacing: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.space-level-2
    &.space-level-3 {
        letter-spacing: 7px;
        .dg-mainMenu {
            .wrapMenuOptions {
                .item {
                    a {
                        label {
                            letter-spacing: 7px;
                        }
                    }
                }
            }
        }
        .menu {
            .dg-menu {
                .accordion {
                    .card-body {
                        ul {
                            li {
                                .description {
                                    letter-spacing: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    //&.space-level-3
}