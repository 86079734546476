.home {
    .dg-init {
        background-image: url("/images/home/banner.webp");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        position: relative;
        width: 100%;

        .wrapImg {
            margin: 0 auto 0;
            padding-top: 105px;
            position: relative;
            width: 310px;
            z-index: 10;

            img {
                width: 100%;
            }

            h2 {
                display: block;
                font-weight: 800;
                text-align: center;
                font-size: 32px;
                margin-top: 28px;

                img {
                    display: inline-block;
                    margin-left: 10px;
                    width: 26px;
                }
            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                list-style: none;
                padding: 0;
                width: 150px;

                li {
                    text-align: center;
                    width: 100%;

                    img {
                        display: block;
                        margin: 15px auto 0;
                        width: 40px;

                        &.lsm {
                            width: 60px;
                        }
                    }
                }
            }

            //ul

            .btn {
                border: 1px solid white;
                color: white;
                text-align: center;
                height: 50px;
                font-weight: 300;
                display: block;
                margin: 30px auto 0;
                border-radius: 4px;
                font-size: 22px;
                position: relative;
                line-height: 40px;
                width: 205px;

                &.lsm {
                    border: 0;
                    width: 65px;
                    margin-top: 0;
                }
                &.audio {
                    border: 0;
                    width: 65px;
                    margin-top: 0;
                }
            }
        }

        //.wrapImg

        .wrapDown {
            position: absolute;
            background-image: url("/images/home/elementos-cocina.webp");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 273px;
            width: 100%;
            bottom: 0;
            left: 0;
        }

        //.wrapDown
    }
}