//Font
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600;800&display=swap');

// Body
$body-bg: #f8fafc;
$gradinet-f: #ee652c;
$gradinet-s: #ed6f2f;
$gradinet-t: #ec762f;
$brand-text-p: #fff;
$brand-text-s: #000;
$brand-color-s: #ee652c;
$brand-color-t: #ee652c;

// Typography
$font-family: 'Raleway', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
