body {
    //ackground-image: linear-gradient(to bottom, $gradinet-f, $gradinet-s, $gradinet-t);
    background-repeat: no-repeat;
    min-height: 100vh;
    font-size: 16px;
    font-family: $font-family;
    color: white;

    #content {
        max-width: 800px;
        margin: 0 auto;
    }

    &.speaker-off {
        .btn {
            &.audio {
                display: none !important;
            }
        }
    }
    &.lsm-off {
        .btn {
            &.lsm {
                display: none !important;
            }
        }
    }

    &.black-white {
        color: white;
        background-image: linear-gradient(to bottom, #000, #000, #000);

        .dg-mainMenu {
            ul {
                background-image: linear-gradient(to bottom, #000, #000, #000);
            }
        }

        .card {
            .card-header {
                button {
                    color: black;
                    font-weight: bold;

                    &.animate {
                        color: $brand-color-s;
                        animation: zoom-in-zoom-out 1.5s ease-out infinite;

                    }
                }
            }
            .card-body {
                background-color: black;

                .wrap-title {
                    .wrap-buttons {
                        .btn {
                            &.audio {
                                background-image: url(/images/icono-audio-w.webp);
                            }
                            &.lsm {
                                background-image: url(/images/icono-lsm-w.webp);
                            }
                        }
                    }
                }

                ul {

                    li {

                        &:nth-child(even) {
                            color: #000;

                            .btn {
                                &.audio {
                                    background-image: url(/images/icono-audio.webp);
                                }
                                &.lsm {
                                    background-image: url(/images/icono-lsm.webp);
                                }
                            }
                        }

                        .btn {
                            &.audio {
                                background-image: url(/images/icono-audio-w.webp);
                            }
                            &.lsm {
                                background-image: url(/images/icono-lsm-w.webp);
                            }
                        }
                    }
                }
            }
        }
    }

    &.noScroll{
        main{
            height: 100vh;
            overflow: hidden;
        }
        .hamburger{
            span{
                &:first-child{
                    transform: rotate(45deg) translate(7px, 7px);
                }
                &:nth-child(2){
                    opacity: 0;
                    transform: translateY(-100%);
                }
                &:nth-child(3){
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
        .dg-mainMenu,
        .dg-mainMenu .wrapMenuOptions {
            opacity: 1;
            visibility: visible;
            overflow: scroll;
        }
    }

    header {
        position: fixed;
        padding-bottom: 20px;
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        z-index: 11;

        .dg-logo {

            img {
                bottom: -8px;
                position: relative;
            }
        }

        .dg-mainMenu{
            padding: 0;
            position: fixed;
            top: 0;
            right: 0;
            bottom:0;
            left: 0;
            z-index: 7;
            opacity: 0;
            visibility: hidden;

            .head {
                background-color: rgba(0, 0, 0, 0.8);
                position: absolute;
                left: 0;
                top: 0;
                height: 115px;
                width: 100%;
                z-index: 10;

                .logo {
                    position: absolute;
                    top: 24px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 180px;
                    padding-right: 19px;
                }

                .close {
                    left: auto;
                    right: 35px;
                    top: 24px;
                    height: 40px;
                    opacity: 1;
                    position: absolute;
                    margin: 0 auto;
                    width: 50px;

                    span{
                        background: white;
                        border-radius: 2px;
                        height: 3px;
                        width: 100%;
                        display: block;
                        margin-bottom: 7px;
                        transition: opacity 250ms ease, transform 250ms ease;

                        &.active {
                            &:first-child{
                                transform: rotate(45deg) translate(16px, 13px);
                            }
                            &:nth-child(2){
                                transform: rotate(-45deg) translate(-7px, 8px);
                            }
                        }

                    }
                }
            }
            //.head



            .wrapMenuOptions {
                background-image: url('/images/menu/background.webp');
                background-size: cover;
                padding: 37vw 10.6666% 20vh;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                transition: opacity 250ms ease, visibility 250ms ease;

                .widget-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    transition: all 0.5s;

                    .switch {
                        display: inline-block;
                        height: 34px;
                        position: relative;
                        width: 60px;

                        input {
                            opacity: 0;

                            &:checked {
                                background-color: #66bb6a;
                            }
                        }
                    }

                    .slider {
                        background-color: #ccc;
                        bottom: 0;
                        cursor: pointer;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        transition: .4s;

                        &.checked {
                            background-color: #66bb6a;

                            &:before {
                                transform: translateX(26px);
                            }
                        }

                        &:before {
                            background-color: #fff;
                            bottom: 4px;
                            content: "";
                            height: 26px;
                            left: 4px;
                            position: absolute;
                            transition: .4s;
                            width: 26px;
                        }
                    }

                    .slider.round {
                        border-radius: 34px;

                        &:before {
                            border-radius: 50%;
                        }
                    }
                }
                //.widget-row

                .item {
                    position: relative;
                    transition: all 0.5s;

                    a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        transition: all 0.5s;

                        &.reset-settings {
                            background-image: url("/images/icon-restart.webp");
                            background-repeat: no-repeat;
                            background-size: 60px auto;
                            background-position: 20px center;
                            background-color: white;
                            color: black;
                            display: block;
                            height: 50px;
                            padding-left: 70px;
                            text-align: center;
                            border-radius: 10px;
                            width: 310px;
                        }
                        &.animate {
                            animation: rotation 2s infinite linear;
                        }

                        img {
                            height: auto;
                            width: 50px;
                            margin: 0 auto 15px;
                            display: block;
                            transition: all 0.5s;
                        }

                        label {
                            color: white;
                            font-size: 16px;
                            display: block;
                            text-align: center;
                            position: relative;
                            width: 90%;
                            transition: all 0.5s;

                            span {
                                position: absolute;
                                right: 15px;
                                top: -20px;
                            }
                        }
                    }
                }
                //.item

                a {
                    &.reset-settings {
                        //background-image: url("/images/icon-restart.webp");
                        //background-repeat: no-repeat;
                        //background-size: 28px auto;
                        //background-position: 20px center;
                        background-color: white;
                        color: black;
                        display: block;
                        height: 55px;
                        padding-left: 47px;
                        text-align: center;
                        border-radius: 10px;
                        width: 310px;
                        font-size: 14px;
                        padding-right: 20px;
                        padding-top: 6px;
                        margin: 20px auto 0;

                        img {
                            display: inline-block;
                            width: 28px;
                            position: absolute;
                            left: 25px;
                            top: 34px;
                        }
                    }
                    &.animate {

                        img {
                            animation: rotation 2s infinite linear;
                        }
                    }
                }

                li{
                    background-repeat: no-repeat;
                    background-position: 89% center;
                    display: flex;
                    min-height: 50px;
                    padding-top: 11px;
                    border-bottom: 1px solid white;
                    padding-bottom: 7px;
                    -webkit-transition: all 0.5s; /* Safari */
                    transition: all 0.5s;

                    &.contrast {
                        background-image: url('/images/ico-contrast.png');
                        background-size: 20px auto;
                    }
                    //&.contrast

                    &.text-up {
                        background-image: url('/images/ico-text-up.png');
                        background-size: 20px auto;
                    }
                    //&.text-up
                    &.text-down {
                        background-image: url('/images/ico-text-down.png');
                        background-size: 20px auto;
                    }
                    //&.text-down
                    &.speaker-function {
                        background-image: url('/images/ico-speaker.png');
                        background-size: 30px auto;
                        background-position: 94% center;

                        &.off {
                            background-image: url('/images/ico-speaker-off.png');
                        }
                    }
                    //&.speaker-function
                    &.lsm-function {
                        background-image: url('/images/ico-lsm.png');
                        background-size: 37px auto;
                        background-position: 94% center;

                        &.off {
                            background-image: url('/images/ico-lsm-off.png');
                        }
                    }
                    //&.lsm-function

                    a{
                        &.ico-accesibility {
                            background-image: url("/images/ico-accesibility.png");
                            background-size: 100% auto;
                            background-repeat: no-repeat;
                            height: 35px;
                            width: 35px;

                            @include media-breakpoint-up(sm) {
                                height: 45px;
                                width: 45px;
                            }
                            //@include screen-sm

                            &:focus, &:hover {
                                background-color: transparent;
                                background-image: url("/images/ico-accesibility-over.png");
                            }
                            //&:focus
                        }
                        //&.ico-accesibility

                        color: white;
                        //font-size: 18px;
                        //padding-top: 18px;
                        //padding-bottom: 18px;
                        display: inline-block;
                        max-width: 80%;
                        -webkit-transition: all 0.5s; /* Safari */
                        transition: all 0.5s;

                    }

                }
            }
        }
        &.stick{
            background-color: rgba(0,0,0, 0.9);
            //box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
        }
        .menuMb{
            padding: 0;
            position: relative;
            z-index: 7;
            .hamburger{
                //background-color: $brand-text-s;
                border-radius: 20px;
                width: 85px;
                padding-top: 8px;
                padding-left: 15px;
                height: 45px;
                padding-right: 15px;

                span{
                    background: white;
                    border-radius: 2px;
                    height: 3px;
                    width: 100%;
                    display: block;
                    margin-bottom: 7px;
                    transition: opacity 250ms ease, transform 250ms ease;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

a {
    &:link,&:focus,&:visited,&:hover{
        font-style: normal;
        text-decoration: none;
    }
}
.btn {
    font-size: inherit;
    line-height: inherit;
}
.ok {
    background-image: url('/images/finish.webp');
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: inline-block;

    &.mini {
        height: 15px;
        margin-left: 4px;
        width: 15px;
        position: relative;
        top: 3px;
    }
    //&.mini
}
//.ok

.accordion {
    .collapse {
        &.show {
            z-index: 3;
        }
    }
    .card-header {
        align-items: center;
        justify-content: space-between;
    }
    //.card-header

    .card-body {
        .wrap-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid;
            margin-bottom: 10px;
            min-height: 50px;

            .title {
                color: $brand-color-s;
                font-weight: 600;
                font-size: 19px;
                width: 60%;
            }
        }
        ul {
            margin: 0;
            padding: 0;

            li {

                list-style: none;
                min-height: 40px;
                display: flex;
                align-items: center;
                padding-left: 15px;
                padding-right: 15px;

                label {
                    font-weight: 200;
                    display: block;
                    width: 62%;
                }
            }
        }
    }
    //.card-body

}
//.accordion

.btn {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    border: 0;
    background-color: transparent;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-weight: 600;

    &.main-text {
        display: inline-block;
        height: 100%;
        width: 62%;
        border-radius: 0;
        z-index: 1;

        &.animate {
            color: $brand-color-s;
            animation: zoom-in-zoom-out 1.5s ease-out infinite;

        }
    }

    &.audio {
        background-image: url("/images/icono-audio-w.webp");
        background-size: 70% auto;
        margin-right: 10px;
    }
    &.lsm {
        background-image: url("/images/icono-lsm-w.webp");
    }
}

.wrap-buttons {
    &.small {
        .btn {
            height: 35px;
            width: 35px;
        }
    }
}

.play-video {
    background-color: rgba(0,0,0, 0.9);
    border-right: 1px solid white;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 85%;
    right: auto;
    z-index: 11;
    transition: all 250ms ease;

    video {
        z-index: 5;
        margin: auto;
        display: block;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
    }

    .play-video-again {
        background-color: transparent;
        background-image: url('/images/replay-video.webp');
        background-size: 100% auto;
        background-position: center center;
        background-repeat: no-repeat;
        border: 0;
        position: absolute;
        height: 32px;
        width: 32px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 80%;
    }

    label {
        position: absolute;
        display: block;
        text-align: center;
        color: white;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 90%;
        font-size: 10px;
        opacity: 0.7;

        &.title {
            font-size: 16px;
            font-weight: 600;
            top: 8%;
            opacity: 1;

            span {
                display: block;
                font-size: 12px;
                font-weight: 200;
                opacity: 0.7;
            }
        }
    }
}

.main-text {
    z-index: 2;

    &.animate {
        color: $brand-color-s;
        animation: zoom-in-zoom-out 1.5s ease-out infinite;
    }
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

.video-play, video {
    border-radius: 20px;
}

.visit-guide {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 98vh;
    width: 100%;
    z-index: 100;

    img {
        display: block;
        margin: 0 auto;
        max-width: 720px;
        width: 100%;
    }

    .close-guide {
        position: absolute;
        color: white;
        width: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        bottom: 130px;

        &.menu {
            bottom: auto;
            top: 21px;
            background-color: transparent;
            height: 50px;
            width: 50px;
            right: 43px;
            left: auto;

            &:after {
                background-color: transparent;
            }
        }

        &:after {
            left: 0;
            background-color: white;
            content: "";
            position: absolute;
            height: 3px;
            width: 100%;
            bottom: -2px;
        }
    }
}

footer {

    .wrapItalyFlag {
        display: flex;
        height: 8px;
        justify-content: normal;
        width: 100%;

        .green {
            background-color: #37931b;
            width: 33.33%;
        }
        .white {
            background-color: white;
            width: 33.33%;
        }
        .red {
            background-color: #ff0000;
            width: 33.33%;
        }
    }
}

.no-padding {
    padding: 0;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
@-webkit-keyframes rotate {
    from {-webkit-transform:rotate(0deg);}
    to {  -webkit-transform:rotate(360deg);}
}

@-moz-keyframes rotate {
    from {-moz-transform:rotate(0deg);}
    to {  -moz-transform:rotate(360deg);}
}

.tg-solciones {
    background-image: url('/images/tg-soluciones-logo.webp');
    background-size: 70px auto;
    background-repeat: no-repeat;
    background-position: center 16px;
    text-align: center;
    font-size: 10px;
    position: relative;
    display: block;
    margin-top: 40px;
    height: 80px;
    width: 100%;
    color: white;
    line-height: 0;
}

.back-to-menu {
    background-image: url('/images/back-arrow.webp');
    background-position: center center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    display: block;
    height: 40px;
    opacity: 0;
    width: 45px;
    visibility: hidden;
    transition: all 250ms ease;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.close-video {
    background-image: url('/images/back-arrow.webp');
    background-position: center center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    width: 44px;
    height: 28px;
    top: 20px;
    left: 20px;
}